import { createSlice } from "@reduxjs/toolkit";

export interface ISettings {
  _producerNumber: number | null;
}

const initialState: ISettings = {
  _producerNumber: null
};

const slice = createSlice({
  name: "ui/settings",
  initialState: initialState,
  reducers: {
    producerNumberChanged: (state, action) => {
      state._producerNumber = action.payload.producerNumber ? Number(action.payload.producerNumber) : null;
    }
  },
  extraReducers: (builder) => {
    builder.addCase("StateReseted", () => {
      return { ...initialState };
    });
  }
});

export const {
  producerNumberChanged
} = slice.actions;

export default slice.reducer;
