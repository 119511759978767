import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "..";
import { Locale } from "../../../config/localization";

export interface I18n {
  locale: Locale
};

const initialState: I18n = {
  locale: Locale.fi
};

const slice = createSlice({
  name: "ui/i18n",
  initialState: initialState,
  reducers: {
    langCodeUpdated: (state, action) => {
      state.locale = action.payload.locale;
    },
    // translationReceived: (state, action) => {
    //   state.supportedLanguages = Object.keys(action.payload.data);
    //   state.translations = action.payload.data;
    // }
  },
  extraReducers: (builder) => {
    builder.addCase("StateReseted", () => {
      return { ...initialState };
    });
  }
});

export const {
  langCodeUpdated,
} = slice.actions;

export default slice.reducer;

export const currentLocale = (state: RootState) => state.ui.i18n.locale;
