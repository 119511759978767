import { MsalAuthenticationResult } from "@azure/msal-react";
import { Box, Container, Typography } from "@mui/material";
import PageHeader from "../PageHeader/PageHeader";

export const Error: React.FC<MsalAuthenticationResult> = ({ error }) => {
  return (
    <Container maxWidth="sm">
      <Box my={5}>
        <PageHeader level="1">Authentication error!</PageHeader>
        <Typography variant="body1">
          An authentication error has occurred ({error?.errorCode}): {error ? (error.errorMessage || error.message) : "unknown error"}
        </Typography>
      </Box>
    </Container>
  )
}
