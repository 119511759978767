import React, { Fragment } from "react";
import { Box, styled } from "@mui/system";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import PageHeader from "../../shared/components/PageHeader/PageHeader";
import QueryResult from "../../shared/components/QueryResult/QueryResult";
import { getSopimus } from "../../shared/services/sopimusService";
import { getSopimusTyyppi } from "../../shared/helpers/SopimusHelper";
import SopimusStatus from "../../shared/components/SopimusStatus/SopimusStatus";
import SopimusHeader from "../../shared/components/Sopimus/SopimusHeader";
import SopimusFooter from "../../shared/components/Sopimus/SopimusFooter";
import TeurasostosopimusSika from "../../shared/components/Sopimus/TeurasostosopimusSika";
import { Button } from "@mui/material";
import KasvatussopimusPorsas from "../../shared/components/Sopimus/KasvatussopimusPorsas";
import KasvatussopimusVasikka from "../../shared/components/Sopimus/KasvatussopimusVasikka";
import ValitysostosopimusPorsas from "../../shared/components/Sopimus/ValitysostosopimusPorsas";
import ValitysostosopimusVasikka from "../../shared/components/Sopimus/ValitysostosopimusVasikka";
import TeurasostosopimusNauta from "../../shared/components/Sopimus/TeurasostosopimusNauta";

const SopimusContainer = styled(Box)({
  minHeight: '100%'
});

const Sopimus: React.FC = () => {
  const params = useParams();
  const navigate = useNavigate();

  const sopimus = useQuery(["sopimus", params.tuottajaNro, params.sopimusId],
    () => getSopimus(params.tuottajaNro || '', params.sopimusId || ''),
    { cacheTime: 0 }
  );

  return (
    <SopimusContainer>
      <QueryResult query={sopimus} loading="Noudetaan tositetta..." result={(data) =>
        <Fragment>
          <PageHeader level="2">
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <div>{getSopimusTyyppi(data)} {data.Sopimusnro}</div>
              <SopimusStatus status={data.Status} />
            </Box>
          </PageHeader>
          <SopimusHeader sopimus={data} />

          {data.Sopimustyyppi === 'TE' && data.Elaintyyppi === 'Sika' &&
            <TeurasostosopimusSika sopimus={data} />
          }
          {data.Sopimustyyppi === 'TE' && data.Elaintyyppi === 'Nauta' &&
            <TeurasostosopimusNauta sopimus={data} />
          }
          {data.Sopimustyyppi === 'OO' && data.Elaintyyppi === 'Sika' &&
            <KasvatussopimusPorsas sopimus={data} />
          }
          {data.Sopimustyyppi === 'OO' && data.Elaintyyppi === 'Nauta' &&
            <KasvatussopimusVasikka sopimus={data} />
          }
          {data.Sopimustyyppi === 'VP' &&
            <ValitysostosopimusPorsas sopimus={data} />
          }
          {data.Sopimustyyppi === 'VV' &&
            <ValitysostosopimusVasikka sopimus={data} />
          }
          
          <SopimusFooter sopimus={data} />
        </Fragment>
      } />
      <Box mt={4}>
        <Button variant="contained" size="small" color="info" onClick={() => navigate('/sopimukset')}>Takaisin arkistoon</Button>
      </Box>
    </SopimusContainer>
  );
};

export default Sopimus;
