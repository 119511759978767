import http from "./httpService";

const API_URL = '/sopimukset';

export const getSopimukset = async (params: any) => {
  const resp = await http.get<any[]>(`${API_URL}`, { params });
  return resp.data;
};

export const getSopimuksetUnapproved = async () => {
  const unapproved = await http.get<any[]>(`${API_URL}`, { params: { status: 'Hyvaksymaton' } });
  const reclamated = await http.get<any[]>(`${API_URL}`, { params: { status: 'Reklamoitu' } });
  return unapproved.data.concat(reclamated.data).sort((a, b) => -a.Noutopvm.localeCompare(b.Noutopvm));
};

export const getSopimus = async (tuottajanro: string, sopimusid: string) => {
  const resp = await http.get<any>(`${API_URL}/${tuottajanro}/${sopimusid}`);
  return resp.data;
};

export const hyvaksySopimus = async (tuottajanro: string, sopimusid: string) => {
  const resp = await http.put<void>(`${API_URL}/${tuottajanro}/${sopimusid}/hyvaksy`);
  return resp.data;
};

export const reklamoiSopimus = async (tuottajanro: string, sopimusid: string, data: any) => {
  const resp = await http.put<void>(`${API_URL}/${tuottajanro}/${sopimusid}/reklamoi`, data);
  return resp.data;
};
