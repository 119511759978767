export const sopimustyyppi: Record<string, string> = {
  TE: 'Teurasostosopimus',
  VP: 'Välitysostosopimus',
  VV: 'Välitysostosopimus',
  OO: 'Kasvatussopimus'
};

export const sopimusStatus: Record<string, string> = {
  Toimitettu: 'Toimitettu',
  Hyvaksymaton: 'Hyväksymätön',
  Hyvaksytty: 'Hyväksytty',
  Reklamoitu: 'Reklamoitu'
};

export const getElaintyyppi = (sopimus: any) =>
  sopimus.Sopimustyyppi === 'TE'
    ? sopimus.Elaintyyppi
    : sopimus.Elaintyyppi === 'Nauta' ? 'Vasikka' : 'Porsas';

export const getSopimusTyyppi = (sopimus: any) => sopimustyyppi[sopimus.Sopimustyyppi] || 'Sopimus';
