import { Box, Grid, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow } from "@mui/material";
import dayjs from "dayjs";
import { Fragment } from "react";
import SopimusHyvaksynta from "./SopimusHyvaksynta";
import SopimusHyvaksyntaLog from "./SopimusHyvaksyntaLog";
import { getSopimusBrandText } from "../../helpers/BrandHelper";

interface KasvatussopimusVasikkaProps {
  sopimus: any;
}

const KasvatussopimusVasikka: React.FC<KasvatussopimusVasikkaProps> = ({ sopimus }) => {
  let brand = getSopimusBrandText(sopimus);
  
  return (
    <Fragment>
      <Box my={3}>
        <Grid container spacing={2}>
          <Grid item md={6}>
            Eläimet toimitettu {dayjs(sopimus.Tiltu.EESopim.TNoutopvm).format('D.M.YYYY')}{' '}
            klo {sopimus.Tiltu.EESopim.TNoutoaika}<br/>
            {sopimus.Tiltu.EESopim.Lisapvm1 && <span>
              Sopimusta muutettu {dayjs(sopimus.Tiltu.EESopim.Lisapvm1).format('D.M.YYYY')}<br/>
            </span>}
            Kasvatussopimusnumero {sopimus.Tiltu.EESopim.Sopimusnro}
            {(sopimus.Tiltu.EESopim.H2 || sopimus.Tiltu.EESopim.H4) && <span>
              <br/>Lisätietoja: {sopimus.Tiltu.EESopim.H2}{sopimus.Tiltu.EESopim.H2 ? ', ' : ''}{sopimus.Tiltu.EESopim.H4}
            </span>}
          </Grid>
          <Grid item md={6}>
            <SopimusHyvaksynta sopimus={sopimus} />
          </Grid>
        </Grid>
      </Box>

      <Box my={3}>
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Eläinlaji</TableCell>
                <TableCell>Tunnus</TableCell>
                <TableCell>Rotu</TableCell>
                <TableCell>Paino kg</TableCell>
                <TableCell>Nupoutus</TableCell>
                <TableCell>Ikä pv</TableCell>
                <TableCell>Kommentti</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sopimus.Tiltu.EEElain.map((elain: any) => 
                <Fragment key={elain.prrowid}>
                  <TableRow className={elain.Lisatietoja ? "lisatietoja" : ""}>
                    <TableCell>{elain.Elainlajinimi}</TableCell>
                    <TableCell>{elain.VirallinenStunnus}</TableCell>
                    <TableCell>{elain.Rotu}</TableCell>
                    <TableCell>{elain.Painokg}</TableCell>
                    <TableCell>{elain.Nupoutuslisa ? 'X' : ''}</TableCell>
                    <TableCell>{elain._IkaPv}</TableCell>
                    <TableCell>{elain.Syykoodi}</TableCell>
                  </TableRow>                    
                  {elain.Lisatietoja && <TableRow>
                    <TableCell sx={{paddingTop:0}}></TableCell>
                    <TableCell sx={{paddingTop:0, fontSize:'90%'}} colSpan={6}>Lisätietoja: {elain.Lisatietoja}</TableCell>
                  </TableRow>}
                </Fragment>
              )}
            </TableBody>
            <TableFooter>
              {sopimus.EEElainKooste?.map((e: any, i: number) => 
                <TableRow key={i}>
                  <TableCell>{e.Item1}</TableCell>                  
                  <TableCell>{e.Item2?.[0]} kpl</TableCell>
                  <TableCell></TableCell>
                  <TableCell>ka {e.Item2?.[4]} kg</TableCell>
                  <TableCell></TableCell>
                  <TableCell>ka {e.Item2?.[5]} pv</TableCell>
                  <TableCell></TableCell>
                </TableRow>                    
              )}
            </TableFooter>
          </Table>
        </TableContainer>
      </Box>

      <Box my={3}>
        {sopimus.Tiltu.EESopim.Toimintayksikko === 'LSO'
          ? <span>Toimitettujen eläinten omistusoikeus siirtyy ostajalle, kun kauppahinta on kokonaisuudessaan maksettu.
              Ostaja sitoutuu myymään vastaanottamansa eläimet {brand} Finland Oy:lle. Lisäksi ostaja sitoutuu noudattamaan
              voimassa olevia {brand}in Tuottajasopimuksen ja Tuottajan käsikirjan täsmentäviä ehtoja.</span>
          : <span>Toimitettujen eläinten omistusoikeus siirtyy tuottajalle, kun kauppahinta on kokonaisuudessaan maksettu.
              Tuottaja sitoutuu myymään ostamansa eläimet Kivikylän Kotipalvaamolle ja noudattamaan voimassa olevia takuu-,
              tilitys- ja perintäehtoja.</span>
        }
      </Box>

      <SopimusHyvaksyntaLog sopimus={sopimus} />
      
    </Fragment>
  )
};

export default KasvatussopimusVasikka;
