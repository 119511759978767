import { setLocale } from 'yup';

/* eslint-disable no-template-curly-in-string */
const initYup = (defaultLocale: string) => {
  setLocale({
    mixed: {
      default: 'Syöte ei kelpaa',
      required: 'Kenttä on pakollinen',
      notType: 'Syöte puuttuu tai on virheellinen',
    },
    number: {
      integer: 'Syötä kokonaisluku',
      min: 'Pienin sallittu luku on ${min}',
      max: 'Suurin sallittu luku on ${max}',
    },
    string: {
      min: 'Syötteen pitää olla vähintään ${min} merkkiä',
      max: 'Syöte saa olla enintään ${max} merkkiä',
    },
    array: {
      min: 'Lisää vähintään ${min} syötettä',
      max: 'Lisää enintään ${max} syötettä',
    },
  });
}

export default initYup;