
import { Breakpoint, IconButton, Menu, MenuItem, styled, useMediaQuery, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAppSelector } from "../../store/hooks";
import MenuIcon from '@mui/icons-material/Menu';

interface NavMenuProps {
  menuItems: IMenuItem[];
  breakPoint?: Breakpoint;
}

export interface IMenuItem {
  title: string;
  link: string;
  roles?: string[];
}

const MenuContainer = styled('div')(({ theme }) => ({
  width: "100%",
  minHeight: "inherit",
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end"
}));

const MenuLink = styled(Link)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  textDecoration: "none",
  color: theme.palette.primary.main,
  fontWeight: "bold",
  padding: "0 10px",
  minHeight: "inherit",
  fontFamily: theme.typography.fontFamily,
  cursor: 'pointer'
}));

function NavMenu({ menuItems, breakPoint = "sm" }: NavMenuProps) {
  const currentUser = useAppSelector((state) => state.auth.currentUser);
  const theme = useTheme();
  const downSm = useMediaQuery(theme.breakpoints.down(breakPoint));
  const navigate = useNavigate();
  
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const menuOpen = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleSelect = (route: string|null) => {
    setAnchorEl(null);
    if (route) {
      navigate(route);
    }
  };
  
  const [items, setItems] = useState<IMenuItem[]>([]);

  useEffect(() => {
    const roleItems: IMenuItem[] = [];
    if (!currentUser) {
      setItems(roleItems);
      return;
    }
    for (let item of menuItems) {
      if (!item.roles || item.roles.length === 0) {
        roleItems.push(item);
      } else {
        for (let role of item.roles) {
          if (currentUser.roles.includes(role)) {
            roleItems.push(item);
          }
        }  
      }
    }
    setItems(roleItems);
  }, [menuItems, currentUser]);

  if (items.length === 0) return null;

  return (items.length === 1 || downSm
    ? <MenuContainer>
        <IconButton onClick={handleClick}>
          <MenuIcon />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={menuOpen}
          onClose={() => handleSelect(null)}
        >
          {items.map((item, index) => (
            <MenuItem key={index} onClick={() => handleSelect(item.link)}>
              {item.title}
            </MenuItem>
          ))}
        </Menu>
      </MenuContainer>
    : <MenuContainer>
        {items.map((item, index) => (
          <MenuLink key={index} to={item.link}>{item.title}</MenuLink>
        ))}
      </MenuContainer>
  );
};

export default NavMenu;
