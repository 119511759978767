import { Fragment, useState } from "react";
import { Button, Menu, MenuItem } from "@mui/material";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

interface Option {
  label: string,
  action: any,
  icon?: any
}

interface OptionsMenuProps {
  label: string,
  options: Option[],
  onSelect: any,
  icon?: any,
  color?: string
}

const OptionsMenu: React.FC<OptionsMenuProps> = ({ label, options, onSelect, icon = null, color = "" }) => {

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Fragment>
      <Button
        color="inherit"
        onClick={handleClick}
        sx={{
          borderRadius: 0,
          backgroundColor: color || null,
          textTransform: 'none',
          '&:hover': {
            backgroundColor: color || null,
          }
        }}
      >
        {icon}
        <span>{label}</span>
        <ArrowDropDownIcon />
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {options.map((option, index) =>
          <MenuItem key={index} onClick={() => onSelect(option.action)}>
            {option.label}
          </MenuItem>
        )}
      </Menu>
    </Fragment>
  )

}
export default OptionsMenu