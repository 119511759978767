import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, TextField } from "@mui/material";
import { ChangeEvent, useEffect, useState } from "react";

interface ReklamoiModalProps {
  open: boolean;
  sopimus: any;
  onClose: any;
}

const ReklamoiModal: React.FC<ReklamoiModalProps> = ({ open, sopimus, onClose }) => {

  const [selite, setSelite] = useState<string>('');

  useEffect(() => {
    setSelite('');
  }, [open]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSelite(event.target.value ?? '');
  };

  const handleClose = (ok: boolean) => {
    onClose(ok ? { selite } : null);
  }

  return (
    <Dialog
      open={open}
      onClose={() => handleClose(false)}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle>
        Reklamoi sopimus {sopimus.Sopimusnro}
      </DialogTitle>
      <DialogContent>
        <Box my={1}>
          <FormControl fullWidth>
            <TextField
              multiline
              fullWidth
              rows={10}
              id="selite"
              label="Reklamaation syy"
              value={selite}
              onChange={handleChange}
            />
          </FormControl>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose(false)}>Peruuta</Button>
        <Button onClick={() => handleClose(true)} disabled={!selite}>Reklamoi</Button>
      </DialogActions>
    </Dialog>
  );
}
export default ReklamoiModal;