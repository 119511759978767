import { Box, Grid, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow } from "@mui/material";
import dayjs from "dayjs";
import { Fragment } from "react";

interface TeurasostosopimusSikaProps {
  sopimus: any;
}

const TeurasostosopimusSika: React.FC<TeurasostosopimusSikaProps> = ({ sopimus }) => {
  return (
    <Fragment>
      <Box my={3}>
        <Grid container spacing={2}>
          <Grid item md={6}>
            Eläimet noudettu {dayjs(sopimus.Tiltu.EESopim.TNoutopvm).format('D.M.YYYY')}{' '}
            klo {sopimus.Tiltu.EESopim.TNoutoaika}<br/>
            {sopimus.Tiltu.EESopim.Lisapvm1 && <span>
              Sopimusta muutettu {dayjs(sopimus.Tiltu.EESopim.Lisapvm1).format('D.M.YYYY')}<br/>
            </span>}
            Teurasostosopimusnumero {sopimus.Tiltu.EESopim.Sopimusnro}
            {(sopimus.Tiltu.EESopim.H2 || sopimus.Tiltu.EESopim.H4) && <span>
              <br/>Lisätietoja: {sopimus.Tiltu.EESopim.H2}{sopimus.Tiltu.EESopim.H2 ? ', ' : ''}{sopimus.Tiltu.EESopim.H4}
            </span>}
          </Grid>
          <Grid item md={6}>

          </Grid>
        </Grid>
      </Box>
      <Box my={3}>
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Eläinlaji</TableCell>
                <TableCell>Osasto</TableCell>
                <TableCell>Tunnus</TableCell>
                <TableCell>Suunniteltu noudettavaksi kpl</TableCell>
                <TableCell>Noudettu kpl</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sopimus.Tiltu.EEElain.map((elain: any) => 
                <Fragment key={elain.prrowid}>
                  <TableRow className={elain.Lisatietoja ? "lisatietoja" : ""}>
                    <TableCell>{elain.Elainlajinimi}</TableCell>
                    <TableCell>{elain.Osasto}</TableCell>
                    <TableCell>{elain.Syntymatunnus}</TableCell>
                    <TableCell>{elain.Kpl}</TableCell>
                    <TableCell>{elain.TotKpl}</TableCell>
                  </TableRow>                    
                  {elain.Lisatietoja && <TableRow>
                    <TableCell sx={{paddingTop:0}}></TableCell>
                    <TableCell sx={{paddingTop:0, fontSize:'90%'}} colSpan={4}>Lisätietoja: {elain.Lisatietoja}</TableCell>
                  </TableRow>}
                </Fragment>
              )}
            </TableBody>
            <TableFooter>
              {sopimus.EEElainKooste?.map((e: any, i: number) => 
                <TableRow key={i}>
                  <TableCell>{e.Item1}</TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell>{e.Item2?.[1]}</TableCell>
                </TableRow>                    
              )}
            </TableFooter>
          </Table>
        </TableContainer>
      </Box>
    </Fragment>
  )
};

export default TeurasostosopimusSika;
