import { Box, Container, Typography } from "@mui/material"
import PageHeader from "../PageHeader/PageHeader"

export const Loading = () => {
  return (
    <Container maxWidth="sm">
      <Box my={5}>
        <PageHeader level="1">Authentication in progress</PageHeader>
        <Typography variant="body1">
          Please wait...
        </Typography>
      </Box>
    </Container>
  )
}
