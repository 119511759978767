import { Box, CircularProgress } from "@mui/material";
import React, { Fragment } from "react";
import { useIsFetching, useIsMutating } from "react-query";

interface LoadingIndicatorProps {
  showAlways?: boolean
}

const LoadingIndicator: React.FC<LoadingIndicatorProps> = ({ showAlways = false }) => {
  const isMutating = useIsMutating()
  const isFetching = useIsFetching()

  return (
    <Fragment>
      {(isFetching || isMutating || showAlways) &&
        <Box sx={{
          position: 'fixed',
          zIndex: 60000,
          top: 0,
          left: 0,
          height: '100%',
          width: '100%',
          backgroundColor: "#ccc",
          opacity: 0.4,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column'
        }}>
          <CircularProgress />
        </Box>
      }
    </Fragment>
  );
};

export default LoadingIndicator;
