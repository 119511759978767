import { AccountInfo } from "@azure/msal-browser";

export default class UserAccount {
  email: string | undefined;
  name: string | undefined;
  roles: string[];
  producers: any[];
  authenticationSource: string | undefined;

  constructor(account?: AccountInfo | null) {
    const claims = account?.idTokenClaims || {} as any;
    this.email = claims.email;
    this.name = this.getName(claims);
    this.roles = claims.roles || [];
    this.producers = claims.authorizationData ? this.getProducers(claims.authorizationData) : [];
    this.authenticationSource = claims.authenticationSource;
  }

  private getName(claims: any): string {
    return claims.first_name && claims.given_name && claims.first_name !== '-' && claims.given_name !== '-'
      ? `${claims.first_name} ${claims.given_name}` : (claims.name || claims.email);
  }

  private getProducers(data: any): any[] {
    try {
      const json = JSON.parse(data);
      return json.Producers || [];
    } catch (err) {
      console.error(`Error parsing authorization data: ${data}`, err);
      return [];
    }
  }
}
