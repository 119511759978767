import { Box, Paper } from "@mui/material";
import dayjs from "dayjs";
import { Fragment } from "react";

interface SopimusHyvaksyntaLogProps {
  sopimus: any;
}

const SopimusHyvaksyntaLog: React.FC<SopimusHyvaksyntaLogProps> = ({ sopimus }) => {
  return (
    <Fragment>
      {sopimus.Reklamaatio != null && <Box my={3}>
        <strong>Tosite reklamoitu{' '}
        {dayjs(sopimus.Reklamaatio.Aika).format('D.M.YYYY H:mm')}{' '}
        ({sopimus.Reklamaatio.Kayttaja})</strong><br/>
        <Paper sx={{ padding: '0.75em', whiteSpace: "pre-line"}}>{sopimus.Reklamaatio.Selite}</Paper>
      </Box>}

      {sopimus.Allekirjoitus != null && <Box my={3}>
        <strong>Tosite hyväksytty{' '}
        {dayjs(sopimus.Allekirjoitus.Aika).format('D.M.YYYY H:mm')}{' '}
        ({sopimus.Allekirjoitus.Kayttaja})</strong>
      </Box>}
    </Fragment>
  )
};

export default SopimusHyvaksyntaLog;
