import React from "react";
import { Box, styled } from "@mui/system";
import { Avatar, List, ListItemButton, ListItemAvatar, Alert } from "@mui/material";
import { useQuery } from "react-query";
import { useAppSelector } from "../../shared/store/hooks";
import AssignmentIcon from '@mui/icons-material/Assignment';
import { useLocation, useNavigate } from "react-router-dom";
import PageHeader from "../../shared/components/PageHeader/PageHeader";
import QueryResult from "../../shared/components/QueryResult/QueryResult";
import { getSopimuksetUnapproved } from "../../shared/services/sopimusService";
import SopimusListItemText from "../../shared/components/Sopimus/SopimusListItemText";

const HomeContainer = styled(Box)({
  minHeight: '100%'
});

const CatalogAvatar = styled(Avatar)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.main
}));

const Home: React.FC = () => {
  const { hash } = useLocation();
  const navigate = useNavigate();
  const producer = useAppSelector((state) => state.auth.producer);

  const hyvaksymattomat = useQuery('hyvaksymattomat', () => getSopimuksetUnapproved(), {
    enabled: !!producer,
    onSuccess(data) {
      if (data.length === 1 && hash === '#hyvaksy') {
        navigate(`/sopimukset/${data[0].Tuottajanro}/${data[0].id}`);
      }
    },
  })

  return (
    <HomeContainer>
      <PageHeader level="2">Hyväksymättömät kasvatussopimukset</PageHeader>
      <Box mt={3}>
        <QueryResult query={hyvaksymattomat} result={(data) => 
          data.length > 0
          ? <List dense disablePadding>{data.map((sopimus: any) =>
              <ListItemButton key={sopimus.id} onClick={() => navigate(`/sopimukset/${sopimus.Tuottajanro}/${sopimus.id}`)}>
                <ListItemAvatar>
                  <CatalogAvatar>
                    <AssignmentIcon />
                  </CatalogAvatar>
                </ListItemAvatar>
                <SopimusListItemText sopimus={sopimus} />
              </ListItemButton>
            )}</List>
          : <Alert severity="success">Ei hyväksymättömiä sopimuksia</Alert>
        } />
      </Box>
    </HomeContainer>
  );
};

export default Home;
