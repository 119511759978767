import { LocalizationProvider } from '@mui/x-date-pickers';
import { CssBaseline, Theme, ThemeProvider } from "@mui/material";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import AppInit from "./shared/components/AppInit/AppInit";
import createTheme from "./shared/themes";
import { useEffect, useState } from "react";
import { Localization } from "@mui/material/locale";
import { getLocalization } from "./config/mui";
import Main from "./shared/components/Main/Main";
import initDayjs from "./config/dayjs";
import initYup from "./config/yup";
import { MsalAuthenticationTemplate, MsalProvider } from "@azure/msal-react";
import { msalInstance, redirectRequest } from "./shared/services/authService";
import { InteractionType } from "@azure/msal-browser";
import { useAppSelector } from "./shared/store/hooks";
import { Loading } from "./shared/components/Auth/Loading";
import { Error } from "./shared/components/Auth/Error";
import { QueryClient, QueryClientProvider } from "react-query";
import { SnackbarProvider } from "notistack";
import { ConfirmProvider } from "material-ui-confirm";

const App = () => {
  const locale = useAppSelector(state => state.ui.i18n.locale);
  const [localization, setLocalization] = useState<Localization>(getLocalization(locale));
  const [theme, setTheme] = useState<Theme>(createTheme(localization));

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
      }
    },
  });

  const confirmOptions = {
    title: 'Vahvista toiminto',
    confirmationText: 'Jatka',
    cancellationText: 'Peruuta',
    allowClose: false
  };

  useEffect(() => {
    initDayjs(locale);
    initYup(locale);
    setLocalization(getLocalization(locale));
    setTheme(createTheme(getLocalization(locale)));
  }, [locale])

  return (
    <MsalProvider instance={msalInstance}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={localization}>
          <MsalAuthenticationTemplate
            interactionType={InteractionType.Redirect}
            authenticationRequest={redirectRequest}
            loadingComponent={Loading}
            errorComponent={Error}
          >
            <ConfirmProvider defaultOptions={confirmOptions}>
              <SnackbarProvider maxSnack={3}>
                <QueryClientProvider client={queryClient}>
                  <AppInit>
                    <Main />
                  </AppInit>
                </QueryClientProvider>
              </SnackbarProvider>
            </ConfirmProvider>
          </MsalAuthenticationTemplate>
        </LocalizationProvider>
      </ThemeProvider>
    </MsalProvider>
  );
}

export default App;
