export const enum Locale {
  en = 'en',
  fi = 'fi',
  se = 'se'
}

export const LocaleList = [
  [Locale.en, 'English'],
  [Locale.fi, 'Suomi'],
  [Locale.se, 'Svenska']
];
