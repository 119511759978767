import React from "react";
import { Box, styled } from "@mui/system";
import { useQuery } from "react-query";
import { useAppSelector } from "../../shared/store/hooks";
import PageHeader from "../../shared/components/PageHeader/PageHeader";
import { getSopimukset } from "../../shared/services/sopimusService";
import SopimusGrid from "../../shared/components/Grids/SopimusGrid";

const SopimuksetContainer = styled(Box)({
  minHeight: '100%'
});

const Sopimukset: React.FC = () => {
  const producer = useAppSelector((state) => state.auth.producer);

  const sopimukset = useQuery('sopimukset', () => getSopimukset({}), { enabled: !!producer })

  return (
    <SopimuksetContainer>
      <PageHeader level="2">Tositearkisto</PageHeader>
      <Box mt={3}>
        <SopimusGrid query={sopimukset} />
      </Box>
    </SopimuksetContainer>
  );
};

export default Sopimukset;
