import { combineReducers } from "redux";
import { persistReducer } from 'reduxjs-toolkit-persist'
import storage from 'reduxjs-toolkit-persist/lib/storage'
import settingsReducer, { ISettings } from "./settings";
import i18nReducer from "./i18n";

const persistConfig: any = {
  key: "ui",
  storage: storage
};

const persistSettingsReducer = persistReducer<ISettings, any>(persistConfig, settingsReducer);

export default combineReducers({
  i18n: i18nReducer,
  settings: persistSettingsReducer,
});
