import { enUS, fiFI, svSE, Localization } from '@mui/material/locale';
import { Locale } from './localization';

export const getLocalization = (locale: Locale = Locale.fi): Localization => {
  switch (locale) {
    case Locale.en: return enUS;
    case Locale.fi: return fiFI;
    case Locale.se: return svSE;
    default: return enUS;
  }
}
