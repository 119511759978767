import { Box, Grid } from "@mui/material";
import { Fragment } from "react";
import { getSopimusBrandText } from "../../helpers/BrandHelper";

interface SopimusHeaderProps {
  sopimus: any;
}

const SopimusHeader: React.FC<SopimusHeaderProps> = ({ sopimus }) => {
  let brand = getSopimusBrandText(sopimus);
  
  return (
    <Fragment>
      <Box my={3}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            {sopimus.Tiltu.EESopim.Toimintayksikko === 'LSO'
              ? <strong>
                  {brand} Finland Oy<br/>
                  PL50<br/>
                  20521 Turku
                </strong>
              : <strong>
                  Kivikylän Kotipalvaamo Oy<br/>
                  Savulaaksontie 121<br/>
                  27230 Lappi
                </strong>
            }
          </Grid>
          <Grid item xs={12} sm={6}>
            Aluevastaava {sopimus.Tiltu.EESopim.kenttaednimi}<br/>
            Puhelin {sopimus.Tiltu.EESopim.kenttaedpuhelin1}
          </Grid>
        </Grid>
      </Box>
      <Box mt={3} mb={4}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <strong>
              {sopimus.Tiltu.EESopim.Tuottajanimi}<br/>
              {sopimus.Tiltu.EESopim.Tuottajaosoite}<br/>
              {sopimus.Tiltu.EESopim.Tuottajapostinro} {sopimus.Tiltu.EESopim.Tuottajapostitmp}
            </strong>
          </Grid>
          <Grid item xs={12} sm={6}>
            Tuottajanumero {sopimus.Tiltu.EESopim.Tuottajanro}<br/>
            Y-tunnus {sopimus.Tiltu.EESopim.LYtunnus}<br/>
            {sopimus.Tiltu.EESopim.Maksutapanimi &&
              <Fragment>Maksutapa {sopimus.Tiltu.EESopim.Maksutapanimi}</Fragment>
            }
          </Grid>
        </Grid>
      </Box>
    </Fragment>
  )
};

export default SopimusHeader;
