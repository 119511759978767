import { createTheme } from "@mui/material";
import { orange } from "@mui/material/colors";
import { Localization } from '@mui/material/locale';
import AvenirNextW1GBold from "../../assets/font/AvenirNextW1G-Bold.otf";
import AvenirNextW1GMedium from "../../assets/font/AvenirNextW1G-Medium.otf";
import AvenirNextW1GRegular from "../../assets/font/AvenirNextW1G-Regular.otf";

const avenirNextW1GBold = {
  fontFamily: "Avenir Next W1G Bold",
  fontWeight: "bolder",
  fontStyle: "normal",
  fontDisplay: "swap",
  src: `url(${AvenirNextW1GBold})`,
};

const avenirNextW1GMedium = {
  fontFamily: "Avenir Next W1G Medium",
  fontWeight: "bold",
  fontStyle: "normal",
  fontDisplay: "swap",
  src: `url(${AvenirNextW1GMedium})`,
};

const avenirNextW1GRegular = {
  fontFamily: "Avenir Next W1G",
  fontWeight: "normal",
  fontStyle: "normal",
  fontDisplay: "swap",
  src: `url(${AvenirNextW1GRegular})`,
};

const createDefaultTheme = (localization: Localization) => {
  return createTheme(
    {
      palette: {
        primary: {
          main: "#152B62",
        },
        secondary: {
          main: "#0077C6",
        },
      },
      typography: {
        fontFamily: [
          '"Avenir Next W1G"',
          '"Avenir Next W1G Medium"',
          '"Avenir Next W1G Bold"'
        ].join(','),
        h1: { fontSize: '2.8rem', fontWeight: 'bold', marginBottom: '1rem' },
        h2: { fontSize: '2.2rem', fontWeight: 'bold', marginBottom: '0.5rem' },
        h3: { fontSize: '1.4rem', fontWeight: 'bold', marginBottom: '0.5rem' },
        h4: { fontSize: '1.2rem', fontWeight: 'bold', marginBottom: '0.5rem' },
        h5: { fontSize: '1.1rem', fontWeight: 'bold' },
        h6: { fontSize: '1rem', fontWeight: 'bold' }
      },
      components: {
        MuiCssBaseline: {
          styleOverrides: {
            '@font-face': avenirNextW1GRegular,
            fallbacks: [
              { '@font-face': avenirNextW1GMedium },
              { '@font-face': avenirNextW1GBold }
            ]
          },
        },
        MuiTableRow: {
          styleOverrides: {
            root: {
              '&.lisatietoja td': {
                borderBottom: 'none'
              },
              '&.lisatietoja td[rowspan="2"]': {
                borderBottom: '1px solid rgba(224, 224, 224, 1) !important'
              }  
            }
          }
        },
        MuiTableCell: {
          styleOverrides: {            
            body: {
              verticalAlign: 'top',
            },
            head: {
              fontWeight: 'bold',
            },
            footer: {
              color: 'black',
              fontSize: '0.85em',
              fontWeight: 'bold'
            }
          }
        },
        MuiTableHead: {
          styleOverrides: {            
            root: {
              borderBottom: '1.5px solid gray'
            }
          },
        },
        MuiTableFooter: {
          styleOverrides: {            
            root: {
              borderTop: '1.5px solid gray'
            }
          },
        }
      },
      custom: {
        value: orange[500],
      },
    },
    localization
  );
}

export default createDefaultTheme;
