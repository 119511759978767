import { configureStore /*, Middleware, MiddlewareArray*/ } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from 'reduxjs-toolkit-persist';
import storage from 'reduxjs-toolkit-persist/lib/storage';
// import logger from 'redux-logger';
import rootReducer from "./rootReducer";

const persistConfig: any = {
  key: "root",
  storage: storage,
  whitelist: ["forms"]
};

const createStore = () => {
  const persistRootReducer = persistReducer(persistConfig, rootReducer) as typeof rootReducer;

  // const middlewares = new MiddlewareArray<Middleware>();

  // if (process.env.NODE_ENV === `development`) {
  //   middlewares.push(logger);
  // }

  const store = configureStore({
    reducer: persistRootReducer,
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware({ serializableCheck: false }) //.concat(middlewares)
  });

  const persistor = persistStore(store);

  return {
    store,
    persistor
  };
};

export const { store, persistor } = createStore();

export const stateReseted = () => ({ type: "StateReseted" });

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
