import { AccountInfo } from "@azure/msal-browser";
import { createSlice } from "@reduxjs/toolkit";
import { Producer } from "../models/apiSchema";
import UserAccount from "../models/UserAccount";

export interface IAuth {
  currentUser: UserAccount | null;
  account: AccountInfo | null;
  producer: Producer | null;
}

const initialState: IAuth = {
  currentUser: null,
  account: null,
  producer: null,
};

const slice = createSlice({
  name: "auth",
  initialState: initialState,
  reducers: {
    activeAccountChanged: (state, action) => {
      if (action.payload.accountInfo) {
        const accountInfo = action.payload.accountInfo as AccountInfo;
        state.account = accountInfo;
        state.currentUser = new UserAccount(accountInfo);
        console.debug(state.currentUser);
      } else {
        state.account = null;
        state.currentUser = null;
      }
    },
    producerChanged: (state, action) => {
      if (action.payload.producer) {
        const producer = action.payload.producer as Producer;
        state.producer = producer;
      } else {
        state.producer = null;
      }
    },
  },
  // extraReducers: (builder) => {
  //   builder.addCase("StateReseted", () => {
  //     return { ...initialState };
  //   });
  // }
});

export const {
  activeAccountChanged,
  producerChanged
} = slice.actions;

export default slice.reducer;

export const toimintayksikko = (producer?: Producer|null|undefined) => (producer?.toimintayksikko || 'LSO').toUpperCase();
