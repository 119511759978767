import React, { Fragment } from "react";
import { Typography } from "@mui/material";

interface PageHeaderProps {
  level: "0" | "1" | "2" | "3" | "4";
  [x: string]: any;
}

const PageHeader: React.FC<PageHeaderProps> = ({ level = "1", children, ...rest }) => {
  return (
    <Fragment>
      {level === "0" && (
        <Typography
          variant={"h1"}
          color={"primary"}
          {...rest}>
          {children}
        </Typography>
      )}

      {level === "1" && (
        <Typography
          variant={"h2"}
          color={"primary"}
          {...rest}>
          {children}
        </Typography>
      )}

      {level === "2" && (
        <Typography
          variant={"h3"}
          color={"primary"}
          {...rest}>
          {children}
        </Typography>
      )}

      {level === "3" && (
        <Typography
          variant={"h4"}
          color={"primary"}
          {...rest}>
          {children}
        </Typography>
      )}

      {level === "4" && (
        <Typography
          variant={"h5"}
          color={"primary"}
          {...rest}>
          {children}
        </Typography>
      )}
    </Fragment>
  );
};

export default PageHeader;
