import {Producer } from "../models/apiSchema";
import http from "./httpService";

const API_URL = '/lastVisited';

export const getLastVisitedProducer = async () => {
  const resp = await http.get<Producer[]>(`${API_URL}`);
  return resp.data;
};

export const setLastVisitedProducer = async () => {
  const resp = await http.put<Producer[]>(`${API_URL}`);
  return resp.data;
};

// export const getProducts = async () => {
//   const resp = await http.get<CatalogList>(`${API_URL}/products`);
//   return resp.data;
// };
