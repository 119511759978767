import { AppBar, Box, Button, styled, Toolbar } from "@mui/material";
import { useAppSelector } from "../../store/hooks";
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import auth from "../../services/authService";
import OptionsMenu from "./OptionsMenu";
import ProducerSelectModal from "../ProducerSelectModal/ProducerSelectModal";
import { useState } from "react";
import { toimintayksikko } from "../../store/auth";

const OptionsAppBar = styled(AppBar)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  backgroundColor: theme.palette.primary.main,
  height: "40px",
  "& > *": {
    minHeight: "40px !important",
  }
}));

const BackLink = styled('a')(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  display: "block",
  whiteSpace: "nowrap",
  fontSize: "13px",
  textDecoration: "none",
  [theme.breakpoints.down("sm")]: {
    display: "none"
  }
}));

const OptionsToolbar = styled(Box)(({ theme }) => ({
  position: "absolute",
  right: "0px",
  height: "100%",
  "& > *": {
    textDecoration: "none",
    color: "#FFFFFF",
    fontSize: "12px",
  },
  overflow:"hidden",
  display:"inherit",
  textAlign: "left"
}));

const OptionButton = styled(Button)(({ theme }) => ({
  color: 'white !important',
  borderRadius: 0,
  textTransform: 'none',
  paddingLeft: '12px',
  paddingRight: '12px',
}));

const Header: React.FC = () => {
  const user = useAppSelector((state) => state.auth.currentUser);
  const producer = useAppSelector((state) => state.auth.producer);

  const [openProducerSelectModal, setProducerSelectModalOpen] = useState(false);

  const handleAccountMenu = (action: any) => {
    if (action === 'logout') {
      auth.logout();
    }
  };

  const handleProducerMenu = (action: any) => {
    if (action === 'change') {
      setProducerSelectModalOpen(true);
    }
  };

  return (
    <OptionsAppBar position="static" elevation={0}>
      <Toolbar variant="dense" sx={{ height: '40px' }}>
        {toimintayksikko(producer) === 'LSO' && process.env.REACT_APP_RETURN_URL &&
          <BackLink href={process.env.REACT_APP_RETURN_URL}>
            &lt; Takaisin Sinettiin
          </BackLink>
        }
        {toimintayksikko(producer) === 'KK' && process.env.REACT_APP_RETURN_URL_KIVINETTI &&
          <BackLink href={process.env.REACT_APP_RETURN_URL_KIVINETTI}>
            &lt; Takaisin Kivinettiin
          </BackLink>
        }
        <OptionsToolbar>
          {user &&
            (user.authenticationSource === 'HKScanAzureAD' || user.producers.length > 1
              ? <OptionsMenu
                  label={producer?.name || '?'}
                  options={[
                    { label: 'Vaihda tuottajaa', action: 'change' }
                  ]}
                  onSelect={(action: any) => handleProducerMenu(action)}
                />
              : <OptionButton color="inherit" disabled>
                  {producer?.name || '?'}
                </OptionButton>
            )
          }
          <OptionsMenu
            label={user?.name || '?'}
            icon={<PersonOutlineIcon sx={{ marginRight: '4px' }} />}
            color="secondary.main"
            options={[
              { label: 'Kirjaudu ulos', action: 'logout' }
            ]}
            onSelect={(action: any) => handleAccountMenu(action)}
          />
        </OptionsToolbar>
      </Toolbar>
      <ProducerSelectModal open={openProducerSelectModal} onClose={() => {setProducerSelectModalOpen(false)}} />
    </OptionsAppBar>
  );
}
export default Header
