import { IconButton } from "@mui/material";
import { Link } from "react-router-dom";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import React, { Fragment } from "react";
import { UseQueryResult } from "react-query";
import QueryResultGrid from "./QueryResultGrid";
import AssignmentIcon from '@mui/icons-material/Assignment';
import { getSopimusTyyppi } from "../../helpers/SopimusHelper";
import SopimusStatus from "../SopimusStatus/SopimusStatus";
import dayjs from "dayjs";

interface SopimusGridProps {
  query: UseQueryResult<any[],unknown>
  action?: (row: any) => void
  [x: string]: any
}

const columns: GridColDef[] = [
  { field: 'Sopimusnro', headerName: 'Sopimus', width: 100,
    renderCell: p => <Link to={`/sopimukset/${p.row.Tuottajanro}/${p.row.id}`}>{p.value}</Link>
  },
  { field: 'Noutopvm', headerName: 'Päivämäärä', width: 100, valueFormatter: p => dayjs(p.value).format('D.M.YYYY') },
  { field: 'Sopimustyyppi', headerName: 'Sopimustyyppi', flex: 2, renderCell: p => getSopimusTyyppi(p.row) },
  { field: 'Lisapvm1', headerName: 'Muutettu', width: 140, renderCell: p => p.value ? dayjs(p.value).format('D.M.YYYY') : '' },
  { field: 'Status', headerName: 'Status', width: 140, renderCell: p => <SopimusStatus status={p.value} /> },
];

const SopimusGrid: React.FC<SopimusGridProps> = ({ query, action, ...rest }) => {

  return (
    <QueryResultGrid
      query={query}
      columns={[...columns, ...!action ? [] : [
        {
          field: '_', headerName: '', width: 60, sortable: false, renderCell: (p: GridRenderCellParams<any>) => <Fragment>
            <IconButton size="small" title="Open" onClick={e => action(p.row)}>
              <AssignmentIcon color="primary" />
            </IconButton>
          </Fragment>
        }
      ]]}
      {...rest}
    />
  );
};

export default SopimusGrid;
