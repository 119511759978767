import { Box, Grid, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow } from "@mui/material";
import dayjs from "dayjs";
import { Fragment } from "react";
import SopimusHyvaksynta from "./SopimusHyvaksynta";
import SopimusHyvaksyntaLog from "./SopimusHyvaksyntaLog";
import { getSopimusBrandText } from "../../helpers/BrandHelper";

interface KasvatussopimusPorsasProps {
  sopimus: any;
}

const KasvatussopimusPorsas: React.FC<KasvatussopimusPorsasProps> = ({ sopimus }) => {
  let brand = getSopimusBrandText(sopimus);

  return (
    <Fragment>
      <Box my={3}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            Eläimet toimitettu {dayjs(sopimus.Tiltu.EESopim.TNoutopvm).format('D.M.YYYY')}{' '}
            klo {sopimus.Tiltu.EESopim.TNoutoaika}<br/>
            {sopimus.Tiltu.EESopim.Lisapvm1 && <span>
              Sopimusta muutettu {dayjs(sopimus.Tiltu.EESopim.Lisapvm1).format('D.M.YYYY')}<br/>
            </span>}
            Kasvatussopimusnumero {sopimus.Tiltu.EESopim.Sopimusnro}
            {(sopimus.Tiltu.EESopim.H2 || sopimus.Tiltu.EESopim.H4) && <span>
              <br/>Lisätietoja: {sopimus.Tiltu.EESopim.H2}{sopimus.Tiltu.EESopim.H2 ? ', ' : ''}{sopimus.Tiltu.EESopim.H4}
            </span>}
          </Grid>
          <Grid item xs={12} md={6}>
            <SopimusHyvaksynta sopimus={sopimus} />
          </Grid>
        </Grid>
      </Box>

      <Box my={3}>
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Eläinlaji</TableCell>
                <TableCell>Tunnus</TableCell>
                <TableCell>Toim. kpl</TableCell>
                <TableCell>Paino kg</TableCell>
                <TableCell>Ka kg</TableCell>
                <TableCell>Os.nro</TableCell>
                <TableCell>Kommentti</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sopimus.Tiltu.EEElain.map((elain: any) => 
                <Fragment key={elain.prrowid}>
                  <TableRow className={elain.Lisatietoja ? "lisatietoja" : ""}>
                    <TableCell rowSpan={elain.Lisatietoja ? 2 : 1}>
                      {elain.Elainlajinimi}
                      {elain.Myyja && <span style={{fontSize:'90%'}}><br/><em>{elain.Myyja}</em></span>}
                    </TableCell>
                    <TableCell>{elain.Syntymatunnus}</TableCell>
                    <TableCell>{elain.TotKpl}</TableCell>
                    <TableCell>{elain.Painokg}</TableCell>
                    <TableCell>{elain._PainoKa}</TableCell>
                    <TableCell>{elain.Osasto}</TableCell>
                    <TableCell>{elain.Syykoodi}</TableCell>
                  </TableRow>                    
                  {elain.Lisatietoja && <TableRow>
                    <TableCell sx={{paddingTop:0, fontSize:'90%'}} colSpan={5}>Lisätietoja: {elain.Lisatietoja}</TableCell>
                  </TableRow>}
                </Fragment>
              )}
            </TableBody>
            <TableFooter>
              {sopimus.EEElainKooste?.map((e: any, i: number) => 
                <TableRow key={i}>
                  <TableCell>{e.Item1}</TableCell>                  
                  <TableCell></TableCell>
                  <TableCell>{e.Item2?.[1]}</TableCell>
                  <TableCell>{e.Item2?.[2]}</TableCell>
                  <TableCell>{e.Item2?.[3]}</TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                </TableRow>                    
              )}
            </TableFooter>
          </Table>
        </TableContainer>
      </Box>

      <Box my={3}>
        {sopimus.Tiltu.EESopim.Toimintayksikko === 'LSO'
          ? <span>Toimitettujen eläinten omistusoikeus siirtyy ostajalle, kun kauppahinta on kokonaisuudessaan maksettu.
              Ostaja sitoutuu myymään vastaanottamansa eläimet {brand} Finland Oy:lle. Lisäksi ostaja sitoutuu noudattamaan
              voimassa olevia {brand}in Tuottajasopimuksen ja Tuottajan käsikirjan täsmentäviä ehtoja.</span>
          : <span>Toimitettujen eläinten omistusoikeus siirtyy tuottajalle, kun kauppahinta on kokonaisuudessaan maksettu.
              Tuottaja sitoutuu myymään ostamansa eläimet Kivikylän Kotipalvaamolle ja noudattamaan voimassa olevia takuu-,
              tilitys- ja perintäehtoja.</span>
        }
      </Box>

      <SopimusHyvaksyntaLog sopimus={sopimus} />

    </Fragment>
  )
};

export default KasvatussopimusPorsas;
