import React from "react";
import Chip from '@mui/material/Chip';
import { sopimusStatus } from "../../helpers/SopimusHelper";

interface SopimusStatusProps {
  status: string;
  chip?: boolean;
}

const colors: Record<string, "default" | "primary" | "secondary" | "error" | "info" | "success" | "warning"> = {
  Toimitettu: 'success',
  Hyvaksymaton: 'warning',
  Hyvaksytty: 'success',
  Reklamoitu: 'error'
}

const ProductStatus: React.FC<SopimusStatusProps> = ({status, chip = true}) => {
  // const locale = useAppSelector((state) => state.ui.i18n.locale);

  return (chip
    ? <Chip size="small" label={sopimusStatus[status] || status} color={colors[status] || 'default'} component="span" />
    : <span>{status}</span>
  );
};

export default ProductStatus;
