import { Box, ListItemText } from "@mui/material";
import { getSopimusTyyppi } from "../../helpers/SopimusHelper";
import dayjs from "dayjs";
import SopimusStatus from "../SopimusStatus/SopimusStatus";

interface SopimusListItemTextProps {
  sopimus: any;
}


const SopimusListItemText: React.FC<SopimusListItemTextProps> = ({ sopimus }) => {
  return (
    <ListItemText
      primary={<Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <strong>
          {getSopimusTyyppi(sopimus)} {sopimus.Sopimusnro} ({dayjs(sopimus.Noutopvm).format('D.M.YYYY')}{sopimus.Lisapvm1 ? `, muutettu ${dayjs(sopimus.Lisapvm1).format('D.M.YYYY')}` : ''})
        </strong>
        <SopimusStatus status={sopimus.Status} />
      </Box>}
    />
  )
};

export default SopimusListItemText;
