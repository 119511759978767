import { useAccount } from "@azure/msal-react";
import { Box, Container, Typography } from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import { getLastVisitedProducer } from "../../services/lastVisitedService";
import { activeAccountChanged, producerChanged } from "../../store/auth";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import LoadingIndicator from "../LoadingIndicator/LoadingIndicator";
import PageHeader from "../PageHeader/PageHeader";

interface AppInitProps {
  children: React.ReactNode;
}

const AppInit: React.FC<AppInitProps> = ({ children }) => {
  const dispatch = useAppDispatch();
  const accountInfo = useAccount();

  const currentUser = useAppSelector((state) => state.auth.currentUser);
  const producer = useAppSelector((state) => state.auth.producer);

  const [initialized, setInitialized] = useState<boolean>(false);
  const [noProducer, setNoProducer] = useState<boolean>(false);

  useEffect(() => {
    if (accountInfo) {
      console.debug("AppInit: updating account-info");
      dispatch(activeAccountChanged({ accountInfo: accountInfo }));
    }
  }, [accountInfo, dispatch])


  useEffect(() => {
    if (!currentUser) return;
    async function fetchProducer() {
      const resp = await getLastVisitedProducer();
      if (!resp?.length) setNoProducer(true);
      dispatch(producerChanged({ producer: resp[0] || null }))
    }
    fetchProducer()
  }, [currentUser, dispatch])

  useEffect(() => {
    setInitialized(!!producer);
  }, [producer]);

  if (!initialized) {
    return <LoadingIndicator showAlways={true} />;
  }

  if (initialized && noProducer) {
    return (
      <Container maxWidth="sm">
        <Box my={5}>
          <PageHeader level="2">Authentication error!</PageHeader>
          <Typography variant="subtitle1">
            Käyttäjään "{accountInfo?.name || accountInfo?.username}" ei ole liitetty tuottajia!
          </Typography>
        </Box>
      </Container>
    )
  }

  return (
    <Fragment>
      <LoadingIndicator />
      {children}
    </Fragment>
  );
};

export default AppInit;
