import * as React from 'react';
import Box from '@mui/material/Box';
import Navigation from './Navigation';
import Options from './Options';

const Header: React.FC = () => {
  return (
    <Box sx={{ flexGrow: 0 }}>
      <Options />
      <Navigation />
    </Box>
  );
}
export default Header
