import * as React from 'react';
import { Box, Grid, Hidden, Container } from '@mui/material';
import { styled } from '@mui/system';
// import { ReactComponent as LogoSvg } from "../../../assets/images/HKScan.svg";
import { ReactComponent as HKScanEnvironmentLeftSvg } from "../../../assets/images/hkscan_environment_left.svg";
import { ReactComponent as HKScanEnvironmentRightSvg } from "../../../assets/images/hkscan_environment_right.svg";

const FooterContainer = styled(Box)(({ theme }) => ({
  flexGrow: 0,
  textAlign: "center",
  color: "#fff",
  fontSize: "0.9em",
  backgroundColor: theme.palette.primary.main
}));

// const Logo = styled(LogoSvg)(({ theme }) => ({
//   height: "20px",
//   pointerEvents: "none",
//   "& > *": {
//     fill: "#fff !important",
//   }
// }));

const LogoContainer = styled(Box)(({ theme }) => ({
  paddingTop: "20%",
  paddingBottom: "10%",
  display: "block",
  whiteSpace: "nowrap",
  minWidth: "120px"
}));

const HKScanEnvironmentLeft = styled(HKScanEnvironmentLeftSvg)(({ theme }) => ({
  paddingTop: "20px",
  transform: "scale(0.6)",
  position: "relative",
  right: "-33%",
  "& > *": {
    fill: "#C9C8C6",
  }
}));

const HKScanEnvironmentRight = styled(HKScanEnvironmentRightSvg)(({ theme }) => ({
  paddingTop: "20px",
  transform: "scale(0.6)",
  position: "relative",
  left: "-33%",
  "& > *": {
    fill: "#C9C8C6",
  }
}));

const Footer: React.FC = () => {
  return (
    <FooterContainer>
      <Container maxWidth={"xl"}>
        <Grid container spacing={5} alignItems="flex-start">
          <Hidden xsDown>
            <Grid item sm={3} md={4}>
              <HKScanEnvironmentLeft />
            </Grid>
          </Hidden>
          <Grid item xs={12} sm={6} md={4}>
            <LogoContainer>
              {/* <Logo /> */}
            </LogoContainer>
            <Box>
              <strong></strong>
            </Box>
          </Grid>
          <Hidden xsDown>
            <Grid item sm={3} md={4}>
              <HKScanEnvironmentRight />
            </Grid>
          </Hidden>
        </Grid>
      </Container>
    </FooterContainer>
  );
}
export default Footer
