import { Locale } from './localization';
import dayjs from 'dayjs';
import isLeapYear from 'dayjs/plugin/isLeapYear';
import 'dayjs/locale/en';
import 'dayjs/locale/fi';
import 'dayjs/locale/sv';

dayjs.extend(isLeapYear)

const initDayjs = (locale: Locale = Locale.en) => {
  switch (locale) {
    case Locale.en: dayjs.locale('en'); break;
    case Locale.fi: dayjs.locale('fi'); break;
    case Locale.se: dayjs.locale('sv'); break;
    default: dayjs.locale('en');
  }
}

export default initDayjs;
