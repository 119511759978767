import { Button, Grid } from "@mui/material";
import { useSnackbar } from "notistack";
import { useMutation, useQueryClient } from "react-query";
import { hyvaksySopimus, reklamoiSopimus } from "../../services/sopimusService";
import { useConfirm } from "material-ui-confirm";
import { Fragment, useState } from "react";
import ReklamoiModal from "./ReklamoiModal";
import { useNavigate } from "react-router-dom";

interface SopimusHyvaksyntaProps {
  sopimus: any;
}

const SopimusHyvaksynta: React.FC<SopimusHyvaksyntaProps> = ({ sopimus }) => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const confirm = useConfirm();
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);

  const hyvaksy = useMutation(() => hyvaksySopimus(sopimus.Tuottajanro, sopimus.id), {
    onSuccess: () => {
      queryClient.invalidateQueries(["sopimus", sopimus.Tuottajanro, sopimus.id]);
      queryClient.refetchQueries();
      enqueueSnackbar(`Sopimus ${sopimus.Sopimusnro} on hyväksytty` , { variant: 'success' });
      navigate('/sopimukset');
    },
    onError: (e, v, c) => {
      enqueueSnackbar('Sopimuksen hyväksyntä epäonnistui', { variant: 'error' });
    }
  });
  
  const handleHyvaksy = () => {
    confirm({description: `Sopimus ${sopimus.Sopimusnro} hyväksytään?`}).then(() => {
      hyvaksy.mutate();
    });
  };

  const reklamoi = useMutation((selite: string) => reklamoiSopimus(sopimus.Tuottajanro, sopimus.id, { selite }), {
    onSuccess: () => {
      queryClient.invalidateQueries(["sopimus", sopimus.Tuottajanro, sopimus.id]);
      queryClient.refetchQueries();
      enqueueSnackbar(`Sopimus ${sopimus.Sopimusnro} on reklamoitu` , { variant: 'success' });
      navigate('/sopimukset');
    },
    onError: (e, v, c) => {
      enqueueSnackbar('Sopimuksen reklamointi epäonnistui', { variant: 'error' });
    }
  });

  const handleReklamoi = () => {
    setOpen(true);
  };

  return (
    <Fragment>
      <Grid container spacing={5}>
        <Grid item xs={12} sm={6}>
          <Button fullWidth variant="contained" color="success" onClick={handleHyvaksy} disabled={sopimus.Status === 'Hyvaksytty'}>Hyväksyn</Button>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Button fullWidth variant="contained" color="error" onClick={handleReklamoi} disabled={sopimus.Status !== 'Hyvaksymaton'}>Reklamoin</Button>
        </Grid>
      </Grid>
      <ReklamoiModal open={open} sopimus={sopimus} onClose={(data: any) => {
        setOpen(false);
        if (data) {
          reklamoi.mutate(data.selite);
        }
      }} />
    </Fragment>
  )
};

export default SopimusHyvaksynta;
