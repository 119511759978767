import { Alert } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { UseQueryResult } from "react-query";

interface QueryResultGridProps {
  query: UseQueryResult<any,unknown>
  columns: GridColDef[]
  getRows?: any;
  [x: string]: any;
}

export const rowsPerPage = 20;

const QueryResultGrid: React.FC<QueryResultGridProps> = ({ query, columns, getRows, ...rest }) => {

  const [columnDefs, setColumnDefs] = useState<GridColDef[]>([]);

  useEffect(() => {
    setColumnDefs(columns.map((c) => {
      c.disableColumnMenu = true;
      // c.resizable = true; // not available in free version
      return c;
    }));
  }, [columns])

  return (
    <DataGrid
      columns={columnDefs}
      rows={query.isSuccess ? (getRows ? getRows(query.data) : query.data) : []}
      loading={query.isLoading}
      error={query.isError ? <Alert severity="error">{String(query.error)}</Alert> : null}
      density="compact"
      disableSelectionOnClick
      autoPageSize
      autoHeight
      pageSize={rowsPerPage}
      rowsPerPageOptions={[]}
      {...rest}
    />
  );
};

export default QueryResultGrid;
