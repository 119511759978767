import * as React from 'react';
import { Container, Paper} from '@mui/material';
import { styled } from '@mui/system';
import backgroundImage from '../../../assets/images/img-default-bg.jpg';

const ContentBackground = styled(Paper)(({ theme }) => ({
  flexGrow: 1,
  backgroundColor: 'gray',
  backgroundImage: `url(${backgroundImage})`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundAttachment: 'fixed',
  padding: '24px',
  [theme.breakpoints.down('md')]: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  borderRadius: 0
}));

const ContentContainer = styled(Paper)(({ theme }) => ({
  backgroundColor: 'white',
  padding: '24px',
  [theme.breakpoints.down('md')]: {
    padding: '16px',
  },
}));

interface ContentProps {
  children: any;
};

const Content: React.FC<ContentProps> = ({ children }) => {
  return (
    <ContentBackground>
      <Container maxWidth="lg">
        <ContentContainer>
          {children}
        </ContentContainer>
      </Container>
    </ContentBackground>
  );
}
export default Content
