import { Alert } from "@mui/material";
import { Fragment } from "react";
import { UseQueryResult } from "react-query";

interface QueryResultProps {
  query: UseQueryResult;
  result (data: any): React.ReactNode;
  loading?: string;
}

const QueryResult: React.FC<QueryResultProps> = ({ query, result, loading = undefined }) => {

  if (query.isLoading)
    return <Alert severity="info">{loading || 'Ladataan tietoja...'}</Alert>;

  if (query.isError)
    return <Alert severity="error">{String(query.error)}</Alert>;

  if (query.isSuccess)
    return <Fragment>{result(query.data)}</Fragment>;

  return null;
};

export default QueryResult;
