import { Box } from "@mui/material";
import { Fragment } from "react";
import { getSopimusBrandText } from "../../helpers/BrandHelper";

interface SopimusFooterProps {
  sopimus: any;
}

const SopimusFooter: React.FC<SopimusFooterProps> = ({ sopimus }) => {
  let brand = getSopimusBrandText(sopimus);
  
  return (
    <Fragment>
      {sopimus.Tiltu.EESopim.Toimintayksikko === 'LSO'
        ? <Box mt={4}>
            {brand} Finland Oy edustaja<br/>
            {sopimus.Tiltu.EEKuorma.Kuljettajanimi}<br/>
            Autoilija {sopimus.Tiltu.EEKuorma.Autoilijanimi}<br/>
            {sopimus.Tiltu.EEKuorma.Autopuh2 && `Puhelin ${sopimus.Tiltu.EEKuorma.Autopuh2}`}
          </Box>
        : <Box mt={4}>
            Kivikylän Kotipalvaamo edustaja<br/>
            {sopimus.Tiltu.EEKuorma.Kuljettajanimi}<br/>
            Autoilija {sopimus.Tiltu.EEKuorma.Autoilijanimi}<br/>
            {sopimus.Tiltu.EEKuorma.Autopuh2 && `Puhelin ${sopimus.Tiltu.EEKuorma.Autopuh2}`}
          </Box>
      }
    </Fragment>
  )
};

export default SopimusFooter;
