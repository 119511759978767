import { Autocomplete, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { Producer } from "../../models/apiSchema";
import { setLastVisitedProducer } from "../../services/lastVisitedService";
import { searchProducers } from "../../services/producerService";
import { stateReseted } from "../../store";
import { producerChanged } from "../../store/auth";
import { useAppDispatch, useAppSelector } from "../../store/hooks";

interface ProducerSelectModalProps {
  open: boolean;
  onClose: any;
}

const ProducerSelectModal: React.FC<ProducerSelectModalProps> = ({ open, onClose }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const producer = useAppSelector((state) => state.auth.producer);

  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState<Producer[]>([]);
  const [filter, setFilter] = useState('');
  const [noOptionsText, setNoOptionsText] = useState("");
  const [selected, setSelected] = useState<Producer | null>(null);

  const updateLastVisitedProducer = useMutation(setLastVisitedProducer, {
    onSuccess: (data) => {
      console.debug("Last visited producer updated", data);
      queryClient.invalidateQueries();
      navigate('/');
    },
    onError: (e) => {
      console.error("Updating last visited producer failed!", e);
      queryClient.invalidateQueries();
      navigate('/');
    }
  });

  useEffect(() => {
    async function search(filter: string) {
      setLoading(true);
      const resp = await searchProducers(filter);
      setLoading(false);
      setOptions(resp.producers);
      if (resp.producers.length === 0) {
        setNoOptionsText('Haulla ei löytynyt tuottajia')
      }
    }

    if (filter) {
      const delayDebounceFn = setTimeout(() => {
        search(filter);
      }, 1000);
      return () => clearTimeout(delayDebounceFn)
    }
    else {
      setOptions([]);
      setNoOptionsText("Hae tilan nimellä...");
    }
  }, [filter]);

  const handleInputChange = (value: string, reason: any) => {
    if (reason === "input") {
      setOptions([]);
      setNoOptionsText("Hae tilan nimellä...");
      setFilter(value);
    }
  };

  const handleChange = (value: Producer | null) => {
    if (value) {
      setSelected(value);
    }
  };

  const resetDialog = () => {
    setSelected(null);
    setOptions([]);
    setFilter('');
  }

  const handleClose = (ok: boolean) => {
    onClose(ok);
    if (ok && selected) {
      dispatch(producerChanged({ producer: selected }));
      dispatch(stateReseted());
      updateLastVisitedProducer.mutate();
    }
    resetDialog();
  }

  return (
    <Dialog
      open={open}
      onClose={() => handleClose(false)}
      maxWidth="xs"
      fullWidth
    >
      <DialogTitle>
        Vaihda tuottajaa
      </DialogTitle>
      <DialogContent>
        <Autocomplete
          inputMode="search"
          // value={selected || producer}
          onInputChange={(_, value, reason) => handleInputChange(value, reason)}
          onChange={(_, value) => handleChange(value)}
          options={[...(selected ? [selected] : producer ? [producer] : []), ...options]}
          filterOptions={() => options}
          loading={loading}
          noOptionsText={noOptionsText}
          loadingText="Haetaan tuottajia..."
          getOptionLabel={(option: Producer) => `${option.name} (${option.id})`}
          isOptionEqualToValue={(option: Producer, value: Producer) => option.id === value.id}
          renderInput={(params) => <TextField {...params} variant="outlined" autoFocus placeholder="Syötä hakusana..." />}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose(false)}>Peruuta</Button>
        <Button onClick={() => handleClose(true)} disabled={!selected}>Vaihda</Button>
      </DialogActions>
    </Dialog>
  );
}
export default ProducerSelectModal;